/*
Google Webfonts helper
-----
*/
/* poppins-regular - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/poppins-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/poppins-v20-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/poppins-v20-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/poppins-v20-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/poppins-v20-latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* 
ANT DESIGN
layout
-------
*/
.site-layout-content {
  min-height: 280px;
  padding: 24px;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.content-left {
  text-align: left;
}

/* 
PROVIDED
general 
-------
*/
body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.ant-layout {
  background: #fff !important;
}

.mainLayout .ant-layout-header {
  background: #e2e2e2;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.bgGray {
  background: #fafafa;
}

/* 
modal content
------------
*/
.modalContent {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 20px;
}

/* 
title holder
------------
*/
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: '';
  background: #1890ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
header
------ 
*/
.ant-layout-header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.header .logo {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 400;
}

.header .logo .fas {
  color: #1890ff;
  margin: 0 5px 0 0;
  font-size: 28px;
}

.header .logo a {
  color: #333;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #333;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #1890ff;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
hero
---- 
*/
.heroBlock {
  background-position: 50% 100%;
  height: 100vh;
}

.heroBlock .img-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('images/start_groupphoto_2023_cut.jpg') no-repeat;
  background-size: cover;
}

.heroBlock .img-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('images/start_waldhaus_cut.jpg') no-repeat;
  background-size: cover;
}

.heroBlock .img-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('images/start_paulus_cut.jpg') no-repeat;
  background-size: cover;
}

.heroBlock .img-4 {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('images/start_lineup_cut.jpg') no-repeat;
  background-size: cover;
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.heroBlock .content {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 30px;
  color: #ffffff;
  text-shadow: 2px 2px 4px #000000;
}

.heroBlock p {
  margin: 0 0 30px;
  color: rgba(255, 255, 255, 1);
  text-shadow: 2px 2px 4px #000000;
  /*
  background-color: rgba(0,0,0,.7);
  bottom: 20px;
  right: 20px;
  opacity: 0.5;
  padding-left: 20px;
  padding-right: 20px;
  */
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}

/* 
block
----- 
*/
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/* 
about
----- 
*/
.aboutBlock .contentHolder {
  text-align: center;
  margin: 0 0 50px;
}

.aboutBlock .icon {
  font-size: 40px;
  color: #1890ff;
  margin: 0 0 10px;
}

.aboutBlock .content {
  text-align: center;
}

/* 
how it works
------------
*/
.worksBlock {
  background: url('images/modal_christmas.jpg') no-repeat;
  background-position: 50% 0;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  position: relative;
}

.worksBlock:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worksBlock h2 {
  color: #fff;
}

.worksBlock .contentHolder {
  text-align: center;
}

.worksBlock .contentHolder button {
  font-size: 40px;
  line-height: 1;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 30px;
  border-radius: 50%;
  width: 110px;
  height: 110px;
}

/* 
faqs
----
*/
.faqBlock .ant-collapse {
  margin: 0 0 40px;
}

.faqBlock .quickSupport {
  text-align: center;
  max-width: 740px;
  margin: auto;
}

.faqBlock .fas {
  margin: 0 10px 0 0;
}

/* 
pricing block
-------------
*/
.pricingBlock .ant-list {
  text-align: center;
}

.pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
  box-shadow: 0 0 12px 2px #f3f3f3;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.pricingBlock .ant-card-head-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.pricingBlock .large {
  font-size: 38px;
  line-height: 1;
  margin: 0 0 20px;
}

.pricingBlock .fab {
  margin: 0 10px 0 0;
}

/* 
contact
-------
*/
.contactBlock .ant-form {
  max-width: 640px;
  margin: auto;
}

.contactBlock .ant-form textarea {
  min-height: 120px;
  height: 120px;
}

/* 
footer
------
*/
.ant-layout-footer {
  background: #111 !important;
  color: #fff !important;
  padding: 60px 0 !important;
  text-align: center;
}

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #1890ff;
}

.footer .goTop:hover {
  opacity: 0.7;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
responsive
----------
*/
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }
  
  .mobileHidden {
    display: none;
  }
}